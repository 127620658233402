import { useState, useCallback } from 'react';
import { useSignal } from '@artica/hooks';
import Input from './form/Input';
import { useStore } from '@immutabl3/store/react';
import Typewriter from './Typewriter';
import Triangle from './icons/Triangle';
import style, {
  maskInput as maskInputStyle,
  button as buttonStyle,
} from './ContactForm.style';

export default function ContactForm({
  children,
  disabled: isDisabled = false,
  ...props
}) {
  const signal = useSignal();
  const hasEmail = !!useStore(['user', 'email']).length;  
  const disabled = hasEmail || isDisabled;
  const [value, setValue] = useState('');
  const onChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  const onSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    
    if (hasEmail) return;

    const data = Object.values(e.target)
      .reduce((obj, field) => {
        if (!field.name) return obj;
        obj[field.name] = field.value;
        return obj;
      }, {});

    signal.emit('user:lead', data);
  }, [signal, hasEmail]);

  return (
    <form css={ style } { ...props } onSubmit={ onSubmit }>
      { children || (
        <p>
          <strong>Become one of the first. </strong><br />
          <small>
            Sign up for Artica updates and early access <br />
            to our seller platform.
          </small>
        </p>
      ) }
      <div css={ maskInputStyle } data-disabled={ disabled }>
        <Typewriter active={ hasEmail }>
          email received.
        </Typewriter>
        <Input
          type="email"
          name="email"
          disabled={ disabled }
          placeholder={ disabled ? '' : 'email' }
          value={ disabled ? '' : value }
          tabIndex={ disabled ? -1 : 0 }
          onChange={ onChange }
          required
        >
          <button
            css={ buttonStyle }
            type="submit"
            tabIndex={ disabled ? -1 : undefined }
          >
            <Triangle right />
          </button>
        </Input>
      </div>
      {/* honeypot */}
      <div className="visually-hidden">
        <Input
          type="text"
          name="name"
          tabIndex={ -1 }
        />
      </div>
    </form>
  );
};