import { useEffect } from 'react';
import { useAlchemy } from '@immutabl3/alchemy';
import { useSignalContext } from '@artica/hooks';
import Launchpad from '../components/Launchpad';
import { Global } from '@emotion/react';
import { global as theme } from '@artica/theme';
import { useContext as useStoreContext } from '@immutabl3/store/react';
import Journey, { config } from '../journey';

const app = Journey();

export default function App({ Component, pageProps }) {
  app.store.merge({ pageProps });
  // const app = useMemo(() => Journey({ pageProps }), []);

  const { store, signal } = app;
  const StoreContext = useStoreContext(store);
  const SignalContext = useSignalContext(signal);
  const AlchemyContext = useAlchemy(config);

  useEffect(() => void app.init(), []);

  return (
    <StoreContext>
      <SignalContext>
        <AlchemyContext>
          <Global styles={ theme } />
          <Launchpad { ...pageProps } />
          <Component
            { ...pageProps }
            StoreContext={ StoreContext }
            SignalContext={ SignalContext }
            AlchemyContext={ AlchemyContext }
          />
        </AlchemyContext>
      </SignalContext>
    </StoreContext>
  );
};